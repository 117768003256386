<template>
  <Layout>
    <NavbarFilter @filterChange="handleFilterChange" :currentProperties="currentProperties" @downloadFile="getFile($event)" :loadingDataStatus="loadingDataSuccess" @fragileLevel="fragile_level = $event" @displayDataBy="currentDisplayBy = $event"/>
    <div class="grid grid-cols-12 gap-x-4">
      <div class="col-span-12 py-4">
        <div class="flex justify-between items-center">
          <h3 class="text-2xl">{{currentDisplayBy == "hh_cnt" ? 'ครัวเรือน' : 'คน'}}เปราะบาง</h3>
          <div class="relative ml-auto sm:ml-0 block custom:hidden bottom-[-5px] mb-2" >
            <div class="dropdown">
              <button class="dropdown-toggle btn transition duration-200 border shadow-sm flex items-center justify-center !p-1 rounded-md font-medium cursor-pointer  !box text-slate-500 ml-auto" aria-expanded="false" @click="expandDropdown = !expandDropdown" @blur="blurHandle">
                <MoreVerticalIcon class="w-5 h-5"/>
              </button>
              <Transition>
              <div class="dropdown-menu w-48 bg-[#FFF] absolute right-0 z-20" v-if="expandDropdown">
                <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                  <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-[#e2e2e2]" @click="getFile('csv')">
                    <DownloadIcon class="h-5 w-5 mr-2" /> CSV
                  </button>
                  <button data-dismiss="dropdown" class="flex items-center rounded-md w-full p-2 text-left hover:bg-[#e2e2e2]" @click="getFile('xlsx')">
                    <DownloadIcon class="h-5 w-5 mr-2" /> Excel
                  </button>
                </div>
              </div>
            </Transition>
            </div>
          </div>
        </div>
        <div class="mt-2 relative fragile-card">
          <div class="absolute right-0 top-[37%] z-10 arrow-right" :class="showArrow ? 'block' : 'hidden'">
            <ArrowRightIcon class="w-8 h-8 text-[#FFF]" id="left_navigate"/>
          </div>
          <div class="absolute left-0 top-[37%] z-10 arrow-left" :class="showArrow ? 'block' : 'hidden'">
            <ArrowRightIcon class="w-8 h-8 text-[#FFF] rotate-180" id="right_navigate"/>
          </div>
          <div class="overflow-x-auto gap-2 flex pb-2 scroll-custom-style" id="card_section">
            <div class="box p-2 py-4 zoom-in flex-col rounded-xl min-w-[289px] relative" :class="{'flex-1' : getCardValueComputed.length == 5}" :style="`background: ${n.bg};`" v-for="(n,index) in getCardValueComputed" :key="n">
              <div class="absolute right-1 top-1" @click="n.modal ? toggleModal() : null" :name="`custom-tooltip-content${index}`" v-if="n.tooltip"  >
                <InfoIcon class="w-6 h-6 opacity-70" :class="n.color" />
              </div>
              <TippyContent :to="`custom-tooltip-content${index}`" v-if="n.tooltip">
                <div v-if="n.html" v-html="n.tooltip.head"></div>
                <div v-else>{{n.tooltip.head}}</div>
                <div v-if="n.html" class="text-xs" v-html="n.tooltip.sub"></div>
                <div class="text-xs" v-else>{{ n.tooltip.sub }}</div>
              </TippyContent>
              <div class="flex items-center">
                <div class="w-12 h-12 rounded-full flex-none bg-white border-2 border-gray flex items-center justify-center text-2xl font-bold" :style="`color: ${n.bg};`">
                  {{ n.iconText }}
                  <SelectionIcon class="w-9 h-9 text-primary-1 flex items-center justify-center" v-if="n.icon" />
                </div>
                <div class="flex-none ml-4 relative" :class="n.color">
                  <div class="text-lg truncate font-bold"> {{n.text}} </div>
                  <div class="mt-1 text-lg h-7" v-if="!n.count && n.count != 0"> </div>
                  <div class="mt-1 text-lg" v-else> {{new Intl.NumberFormat().format(n.count)}} {{currentDisplayBy == "hh_cnt" ? 'ครัวเรือน' : 'คน'}} </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-4 map-height">
      <div class="col-span-12 md:col-span-7 relative">
        <div class="absolute bg-[#f5f5f5] h-full w-full flex" v-if="loadingDataSuccess"><div class="m-auto"><LoadingOvalIcon /></div></div>
        <Map :geoData="geoData" :currentLevel="currentLevel" @geoChange="handleGeoChange" :fragileLevel="fragile_level" :displayBy="currentDisplayBy" />
      </div>
      <div class="col-span-12 md:col-span-5 md:overflow-scroll" :class="{'relative' : loadingDataSuccess}">
        <div class="absolute bg-[#f5f5f5] h-full w-full flex" v-if="loadingDataSuccess"><div class="m-auto"><LoadingOvalIcon /></div></div>
        <div class="bg-[#f5f5f5] h-full p-3 md:overflow-scroll scroll-custom-style">
          <div class="flex justify-between">
            <div><h2 class="text-lg">{{currentDisplayBy == "hh_cnt" ? 'ครัวเรือน' : 'คน'}}เปราะบางแยกราย{{ currentLevel == "all" ? 'จังหวัด' : currentLevel == "province" ? 'อำเภอ' : 'ตำบล' }}</h2></div>
            <div class="flex gap-2 items-center">
              <button class="bg-gray bg-opacity-50 p-1 rounded" :class="{'active bg-primary-btn': viewBy == 'chart'}" @click="viewBy = 'chart'"><BarChartIcon class="h-8 w-8 text-white" /></button>
              <button class="bg-gray bg-opacity-50 p-1 rounded" @click="viewBy = 'table'" :class="{'active bg-primary-btn': viewBy == 'table'}"><TableIcon class="h-8 w-8 text-white" /></button>
            </div>
          </div>
          <div class="mt-3">
            <DataTable :data-array="dataLevel" :header="headerTableComputed" v-show="dataLevel.length && geoData && viewBy == 'table'" :fragileLevel="fragile_level" :displayBy="currentDisplayBy"></DataTable>
            <div v-show="dataLevel.length && viewBy == 'chart'">
              <StackedBarChartFragile
                chart-id="fragile"
                :series="dataLevel"
                :keyAccess="getKeyAccess"
                :fragileLevel="fragile_level"
                :displayBy="currentDisplayBy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-4 mt-3">
      <div class="col-span-12 md:col-span-7">
        <div>
          <h2 class="py-1 font-bold text-md">ครัวเรือนเปราะบาง TPMAP</h2>
          <div class="pl-4 info-detail">
            <p><span class="font-bold">เปราะบางระดับ 0</span> คือ ครัวเรือนที่มีรายได้เฉลี่ยต่อปีมากกว่า 100,000 บาท/คน แต่มีบุคคลที่อยู่ในภาวะพึ่งพิง</p>
            <p><span class="font-bold">เปราะบางระดับ 1</span> คือ ครัวเรือนที่มีรายได้เฉลี่ยต่อปีน้อยกว่า 100,000 บาท/คน</p>
            <p><span class="font-bold">เปราะบางระดับ 2</span> คือ ครัวเรือนที่มีรายได้เฉลี่ยต่อปีน้อยกว่า 100,000 บาท/คน และมีบุคคลที่อยู่ในภาวะพึ่งพิง 1 - 2 คน</p>
            <p><span class="font-bold">เปราะบางระดับ 3</span> คือ ครัวเรือนที่มีรายได้เฉลี่ยต่อปีน้อยกว่า 100,000 บาท/คน และมีบุคคลที่อยู่ในภาวะพึ่งพิงมากกว่า 2 คนขึ้นไป</p>
            <div class="mt-5">
              <p>*หมายเหตุ</p>
              <p>บุคคลที่อยู่ในภาวะพึ่งพิง เช่น ผู้เยาว์ (0 - 18 ปี) ผู้สูงอายุ (60 ปีขึ้นไป) คนพิการ และผู้ป่วยติดเตียง (ป่วยเรื้อรัง + ช่วยเหลือตัวเองไม่ได้)</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-span-12 md:col-span-5">
        <div class="col-span-12 font-bold text-md">
          บูรณาการข้อมูลจากฐานข้อมูล:
        </div>
        <div class="col-span-12 py-1">
          <div class="flex gap-2 items-center">
            <div>
              <img  width="40" src="@/assets/images/cdd.jpg">
            </div>
            <div>
              <div>จปฐ โดย กรมการพัฒนาชุมชน</div>
              <div>(จปฐ 2565)</div>
            </div>
          </div>
        </div>
        <div class="col-span-12 pb-1">
          <div class="flex gap-2 items-center">
            <div class="w-[40px]">
              <img width="35" class="m-auto" src="@/assets/images/mof.png">
            </div>
            <div>
              <div>บัตรสวัสดิการแห่งรัฐ โดย กระทรวงการคลัง</div>
              <div>(ม.ค.2565)</div>
            </div>
          </div>
        </div>
        <div class="col-span-12 pb-1">
          <div class="flex gap-2 items-center">
            <div class="w-[40px]">
              <img width="35" class="m-auto" src="@/assets/images/dla.gif">
            </div>
            <div>
              <div>การจ่ายเบี้ยยังชีพ โดย กรมส่งเสริมการปกครองท้องถิ่น</div>
              <div>(ก.พ.2565)</div>
            </div>
          </div>
        </div>
        <div class="col-span-12 pb-1">
          <div class="flex gap-2 items-center">
            <div class="w-[40px]">
              <img width="30" class="m-auto" src="@/assets/images/moe.png">
            </div>
            <div>
              <div>ฐานข้อมูลนักเรียน โดย กระทรวงศึกษาธิการ</div>
              <div>(ณ ภาคการศึกษา 1/2564)</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <Modal v-model="modalActive" header="ครัวเรือนเปราะบาง TPMAP">
    <div>
      <p><span class="font-bold">เปราะบางระดับ 0</span> คือ ครัวเรือนที่มีรายได้เฉลี่ยต่อปีมากกว่า 100,000 บาท/คน แต่มีบุคคลที่อยู่ในภาวะพึ่งพิง</p>
      <p><span class="font-bold">เปราะบางระดับ 1</span> คือ ครัวเรือนที่มีรายได้เฉลี่ยต่อปีน้อยกว่า 100,000 บาท/คน</p>
      <p><span class="font-bold">เปราะบางระดับ 2</span> คือ ครัวเรือนที่มีรายได้เฉลี่ยต่อปีน้อยกว่า 100,000 บาท/คน และมีบุคคลที่อยู่ในภาวะพึ่งพิง 1 - 2 คน</p>
      <p><span class="font-bold">เปราะบางระดับ 3</span> คือ ครัวเรือนที่มีรายได้เฉลี่ยต่อปีน้อยกว่า 100,000 บาท/คน และมีบุคคลที่อยู่ในภาวะพึ่งพิงมากกว่า 2 คนขึ้นไป</p>
      <p class="mt-4">*หมายเหตุ</p>
      <p>บุคคลที่อยู่ในภาวะพึ่งพิง เช่น ผู้เยาว์ (0 - 18 ปี) ผู้สูงอายุ (60 ปีขึ้นไป) คนพิการ และผู้ป่วยติดเตียง (ป่วยเรื้อรัง + ช่วยเหลือตัวเองไม่ได้)</p>
    </div>
  </Modal>
</template>

<script setup>
import Layout from "@/layout/index.vue"
import NavbarFilter from "@/components/navbar-filter/index.vue"
import Map from "@/components/map/index.vue"
import { ref, computed, onMounted, onUnmounted, watch } from "vue"
import axios from "axios"
import { useRoute, useRouter } from "vue-router"
import DataTable from "@/components/fragile-table/index.vue"
import StackedBarChartFragile from "@/components/stacked-bar-chart-fragile/index.vue"
import { utils, writeFile } from "xlsx"

const modalActive = ref(null)
const expandDropdown = ref(false)
const router = useRouter()
const route = useRoute()
const data = ref()
const geoData = ref([])
let api_path = "provinces"
const currentLevel = ref("all")
const currentYear = ref(null)
const currentProperties = ref(null)
const currentId = ref(null)
const dataLevel = ref([])
const rawData = ref([])
const dataStat = ref()
const viewBy = ref("chart")
const loadingDataSuccess = ref(true)
const fragile_level = ref(["fragile_lv0", "fragile_lv1", "fragile_lv2", "fragile_lv3"])
const currentDisplayBy = ref("hh_cnt")

const boxCard = ref([
  {
    text: "เปราะบางทั้งหมด",
    bg:  "#e7e8f8",
    count: null,
    icon: "search",
    color: "text-dark",
    modal: true,
    tooltip:{
      head: "กรุณาคลิกเพื่อแสดงรายละเอียด"
    }
  },
  {
    text: "เปราะบางระดับ 0",
    bg: "#dadbf6",
    count: null,
    iconText: "0",
    color: "text-dark",
    tooltip:{
      head: "เปราะบางระดับ 0 คือ ครัวเรือนที่มีรายได้เฉลี่ยต่อปีมากกว่า 100,000 บาท/คน แต่มีบุคคลที่อยู่ในภาวะพึ่งพิง",
      // sub: "* บุคคลที่อยู่ในภาวะพึ่งพิง เช่น เด็กเล็ก (0 - 18 ปี) ผู้สูงอายุ (60 ปีขึ้นไป) คนพิการ และผู้ป่วยติดเตียง (ป่วยเรื้อรัง + ช่วยเหลือตัวเองไม่ได้)"
    }
  },
  {
    text: "เปราะบางระดับ 1",
    bg: "#bebff1",
    count: null,
    iconText: "1",
    color: "text-dark",
    tooltip:{
      head: "เปราะบางระดับ 1 คือ ครัวเรือนที่มีรายได้เฉลี่ยต่อปีน้อยกว่า 100,000 บาท/คน",
      // sub: "* บุคคลที่อยู่ในภาวะพึ่งพิง เช่น เด็กเล็ก (0 - 18 ปี) ผู้สูงอายุ (60 ปีขึ้นไป) คนพิการ และผู้ป่วยติดเตียง (ป่วยเรื้อรัง + ช่วยเหลือตัวเองไม่ได้)"
    }
  },
  {
    text: "เปราะบางระดับ 2",
    bg: "#7b7fe6",
    count: null,
    iconText: "2",
    color: "text-white",
    tooltip:{
      head: "เปราะบางระดับ 2 คือ ครัวเรือนที่มีรายได้เฉลี่ยต่อปีน้อยกว่า 100,000 บาท/คน และมีบุคคลที่อยู่ในภาวะพึ่งพิง 1 - 2 คน",
      // sub: "* บุคคลที่อยู่ในภาวะพึ่งพิง เช่น เด็กเล็ก (0 - 18 ปี) ผู้สูงอายุ (60 ปีขึ้นไป) คนพิการ และผู้ป่วยติดเตียง (ป่วยเรื้อรัง + ช่วยเหลือตัวเองไม่ได้)"
    }
  },
  {
    text: "เปราะบางระดับ 3",
    bg: "#5d62e1",
    count: null,
    iconText: "3",
    color: "text-white",
    tooltip:{
      head: "เปราะบางระดับ 3 คือ ครัวเรือนที่มีรายได้เฉลี่ยต่อปีน้อยกว่า 100,000 บาท/คน และมีบุคคลที่อยู่ในภาวะพึ่งพิงมากกว่า 2 คนขึ้นไป",
      // sub: "* บุคคลที่อยู่ในภาวะพึ่งพิง เช่น เด็กเล็ก (0 - 18 ปี) ผู้สูงอายุ (60 ปีขึ้นไป) คนพิการ และผู้ป่วยติดเตียง (ป่วยเรื้อรัง + ช่วยเหลือตัวเองไม่ได้)"
    }
  }
])

const headerTableComputed = computed(()=>{
  let key1, key2 = "fragile_total"
  if(fragile_level.value && currentLevel.value == "all"){
    key1 = "province_name"
  }
  else if(fragile_level.value && currentLevel.value == "province"){
    key1 = "amphur_name"
  }
  else if(fragile_level.value && (currentLevel.value == "amphur" || currentLevel.value == "tambol")){
    key1 = "tambon_name"
  }
  return [{key: key1, th: "พื้นที่", sort: "asc"}, {key: key2, th: `จำนวนครัว${currentDisplayBy.value == 'hh_cnt' ? 'ครัวเรือน' : 'คน'}`, sort: "asc"}]
})
const getData = async () =>{
  let params = {
    year: currentYear.value
  }
  if(currentLevel.value == "province"){
    params["province_id"] = currentId.value
  }
  else if(currentLevel.value == "amphur"){
    params["amphur_id"] = currentId.value
  }
  else if(currentLevel.value == "tambol"){
    params["tambon_id"] = currentId.value
  }

  const res = await axios.get(`${process.env.VUE_APP_BASE_API}/fragile/level`, {params}).catch(err=>err)
  dataLevel.value = res.data
  rawData.value = JSON.parse(JSON.stringify(res.data)).sort((a,b)=> a[headerTableComputed.value[0].key].localeCompare(b[headerTableComputed.value[0].key]));
  return res
}

const getGeoJson = async (path) => {
  loadingDataSuccess.value = true
    const res = await axios.get(`${process.env.VUE_APP_BASE_API}/map/coordinate/${path}`).catch(()=>console.log('error'))
    if(res.status == 200){
      let tempGeo = res.data
      if(currentLevel.value == "tambol"){
        tempGeo = res.data.filter(x=> x.properties.TAMBOL_ID == currentId.value)
      }
      const data_res = await getData()
      if(data_res.status == 200){
        for(let item_area of tempGeo){
          if(currentLevel.value == "all"){
            let data = data_res.data.find(x => x.province_id == item_area.properties.PROVINCE_ID)
            if(data && item_area.properties.PROVINCE_ID == data?.province_id ){
              item_area.properties.fragile_obj = data
            }
          }
          else if(currentLevel.value == "province"){
            let data = data_res.data.find(x => x.amphur_id == item_area.properties.AMPHUR_ID)
            if(data && item_area.properties.AMPHUR_ID == data?.amphur_id ){
              item_area.properties.fragile_obj = data
            }
          }
          else if(currentLevel.value == "amphur"){
            let data = data_res.data.find(x => x.tambon_id == item_area.properties.TAMBOL_ID)
            if(data && item_area.properties.TAMBOL_ID == data?.tambon_id ){
              item_area.properties.fragile_obj = data
            }
          }
          else if(currentLevel.value == "tambol"){
            let data = data_res.data.find(x => x.tambon_id == item_area.properties.TAMBOL_ID)
            if(data && item_area.properties.TAMBOL_ID == data?.tambon_id ){
              item_area.properties.fragile_obj = data
            }
          }
        }
        geoData.value = tempGeo
        loadingDataSuccess.value = false
      }
      else{
        geoData.value = tempGeo
        loadingDataSuccess.value = false
      }
    }
}

const handleGeoChange = (data) => {
  if(data?.level == "province"){
    currentProperties.value = {
      level: data.level,
      province: {
        PROVINCE_NAME_TH: data.properties.PROVINCE_NAME_TH,
        PROVINCE_NAME_EN: data.properties.PROVINCE_NAME_EN,
        _id: {
          PROVINCE_ID: data.properties.PROVINCE_ID
        }
      }
    }
  }
  else if(data?.level == "amphur"){
    currentProperties.value = {
      level: data.level,
      amphur: {
        PROVINCE_NAME_TH: data.properties.PROVINCE_NAME_TH,
        PROVINCE_NAME_EN: data.properties.PROVINCE_NAME_EN,
        AMPHUR_NAME_TH: data.properties.AMPHUR_NAME_TH,
        AMPHUR_NAME_EN: data.properties.AMPHUR_NAME_EN,
        _id: {
          PROVINCE_ID: data.properties.PROVINCE_ID,
          AMPHUR_ID: data.properties.AMPHUR_ID
        }
      }
    }
  }
  else if(data?.level == "tambol"){
    currentProperties.value = {
      level: data.level,
      tambol: {
        PROVINCE_NAME_TH: data.properties.PROVINCE_NAME_TH,
        PROVINCE_NAME_EN: data.properties.PROVINCE_NAME_EN,
        AMPHUR_NAME_TH: data.properties.AMPHUR_NAME_TH,
        AMPHUR_NAME_EN: data.properties.AMPHUR_NAME_EN,
        TAMBOL_NAME_TH: data.properties.TAMBOL_NAME_TH,
        TAMBOL_NAME_EN: data.properties.TAMBOL_NAME_EN,
        _id: {
          PROVINCE_ID: data.properties.PROVINCE_ID,
          AMPHUR_ID: data.properties.AMPHUR_ID,
          TAMBOL_ID: data.properties.TAMBOL_ID
        }
      }
    }
  }
}
const getFragileStat = async () => {
  let params = {
    year: currentYear.value
  }
  if(currentLevel.value == "province"){
    params["province_id"] = currentId.value
  }
  else if(currentLevel.value == "amphur"){
    params["amphur_id"] = currentId.value
  }
  else if(currentLevel.value == "tambol"){
    params["tambon_id"] = currentId.value
  }
  const res = await axios.get(`${process.env.VUE_APP_BASE_API}/fragile/level/stat`, {params}).catch(err=>err)
  dataStat.value = res.data
}

const handleFilterChange = (data) =>{
  let params = {
    year: data.year,
  }
  currentYear.value = data.year
  currentLevel.value = data?.level
  let path
  if(data?.level == "province"){
    path = `amphurs/${data.province?._id?.PROVINCE_ID}`
    params.level = data.province?._id?.PROVINCE_ID
    currentId.value = data.province?._id?.PROVINCE_ID
  }
  else if(data?.level == "amphur"){
    path = `tambols/${data.amphur?._id?.AMPHUR_ID}`
    params.level = data.amphur?._id?.AMPHUR_ID
    currentId.value = data.amphur?._id?.AMPHUR_ID
  }
  else if(data?.level == "tambol"){
    path = `tambols/${data.tambol?._id?.AMPHUR_ID}`
    params.level = data.tambol?._id?.TAMBOL_ID
    currentId.value = data.tambol?._id?.TAMBOL_ID
  }
  else{
    path = api_path
  }
  if(path)
    getGeoJson(path)
  
  getFragileStat()

}
const getKeyAccess = computed(()=>{
  let key
  if(currentLevel.value == "province"){
    key = "amphur_name"
  }
  else if(currentLevel.value == "amphur" || currentLevel.value == "tambol"){
    key = "tambon_name"
  }
  else{
    key = "province_name"
  }
  return key
})
const getTotalFragileLevel = computed(()=>{
  let sum = 0
  for(let i of fragile_level.value){
    sum += dataStat.value[currentDisplayBy.value][i]
  }
  return sum
})
const getCardValueComputed = computed(()=>{
  if(dataStat.value){
    let temp = []
    if(fragile_level.value.length == 4){
      temp.push({
        text: "เปราะบางทั้งหมด",
        bg: "#e7e8f8",
        count: getTotalFragileLevel.value,
        icon: "icon",
        color: "text-dark",
        modal: true,
        tooltip:{
          head: "กรุณาคลิกเพื่อแสดงรายละเอียด"
        }
      })
    }
    if(fragile_level.value.includes("fragile_lv0")){
      temp.push({
        text: "เปราะบางระดับ 0",
        bg: "#dadbf6",
        count: fragile_level.value.includes("fragile_lv0") ? dataStat.value[currentDisplayBy.value].fragile_lv0 : 0,
        iconText: "0",
        color: "text-dark",
        tooltip:{
          head: "เปราะบางระดับ 0 คือ ครัวเรือนที่มีรายได้เฉลี่ยต่อปีมากกว่า 100,000 บาท/คน แต่มีบุคคลที่อยู่ในภาวะพึ่งพิง"
        }
      })
    }
    if(fragile_level.value.includes("fragile_lv1")){
      temp.push({
        text: "เปราะบางระดับ 1",
        bg: "#bebff1",
        count: fragile_level.value.includes("fragile_lv1") ? dataStat.value[currentDisplayBy.value].fragile_lv1 : 0,
        iconText: "1",
        color: "text-dark",
        tooltip:{
          head: "เปราะบางระดับ 1 คือ ครัวเรือนที่มีรายได้เฉลี่ยต่อปีน้อยกว่า 100,000 บาท/คน"
        }
      })
    }
    if(fragile_level.value.includes("fragile_lv2")){
      temp.push({
        text: "เปราะบางระดับ 2",
        bg: "#7b7fe6",
        count: fragile_level.value.includes("fragile_lv2") ? dataStat.value[currentDisplayBy.value].fragile_lv2 : 0,
        iconText: "2",
        color: "text-white",
        tooltip:{
          head: "เปราะบางระดับ 2 คือ ครัวเรือนที่มีรายได้เฉลี่ยต่อปีน้อยกว่า 100,000 บาท/คน และมีบุคคลที่อยู่ในภาวะพึ่งพิง 1 - 2 คน"
        }
      })
    }
    if(fragile_level.value.includes("fragile_lv3")){
      temp.push({
        text: "เปราะบางระดับ 3",
        bg: "#5d62e1",
        count: fragile_level.value.includes("fragile_lv3") ? dataStat.value[currentDisplayBy.value].fragile_lv3 : 0,
        iconText: "3",
        color: "text-white",
        tooltip:{
          head: "เปราะบางระดับ 3 คือ ครัวเรือนที่มีรายได้เฉลี่ยต่อปีน้อยกว่า 100,000 บาท/คน และมีบุคคลที่อยู่ในภาวะพึ่งพิงมากกว่า 2 คนขึ้นไป"
        }
      })
    }
    return temp
  }
  return boxCard.value
})

watch(getCardValueComputed,()=>{
  setTimeout(()=>{
    resizeScreen()
  }, 300)
})
const getFile = (type) =>{
  expandDropdown.value = false
  let newData = rawData.value.map((item)=>{
    let temp = {
      "พื้นที่": null,
      "จำนวนครัวเรือนเปราะบางทั้งหมด": item.hh_cnt.fragile_total,
      "จำนวนครัวเรือนเปราะบาง ระดับ 0": item.hh_cnt.fragile_lv0,
      "จำนวนครัวเรือนเปราะบาง ระดับ 1": item.hh_cnt.fragile_lv1,
      "จำนวนครัวเรือนเปราะบาง ระดับ 2": item.hh_cnt.fragile_lv2,
      "จำนวนครัวเรือนเปราะบาง ระดับ 3": item.hh_cnt.fragile_lv3,
      "จำนวนคนเปราะบางทั้งหมด": item.member_cnt.fragile_total,
      "จำนวนคนเปราะบาง ระดับ 0": item.member_cnt.fragile_lv0,
      "จำนวนคนเปราะบาง ระดับ 1": item.member_cnt.fragile_lv1,
      "จำนวนคนเปราะบาง ระดับ 2": item.member_cnt.fragile_lv2,
      "จำนวนคนเปราะบาง ระดับ 3": item.member_cnt.fragile_lv3,
    }
    if(currentLevel.value == "all"){
      temp["พื้นที่"] = item.province_name
    }
    else if(currentLevel.value == "province"){
      temp["พื้นที่"] = item.amphur_name
    }
    else if(currentLevel.value == "amphur" || currentLevel.value == "tambol"){
      temp["พื้นที่"] = item.tambon_name
    }
    return temp
  })
  const ws = utils.json_to_sheet(newData)
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet1");
    //for csv
    if(type == "csv"){
        writeFile(wb, "ครัวเรือนเปราะบาง.csv");
    }
    //for excel
    if(type == "xlsx"){
        writeFile(wb, "ครัวเรือนเปราะบาง.xlsx");
    }
}
const blurHandle = () =>{
  setTimeout(() => expandDropdown.value = false, 300);
}

const toggleModal = () => {
  modalActive.value = !modalActive.value
}
const showArrow = ref(false)

const resizeScreen = () => {
  const div = document.getElementById('card_section');
  if(div.clientWidth <= (div.scrollWidth - 20) && ( div.scrollWidth != div.clientWidth)){
    showArrow.value = true
  }
  else{
    showArrow.value = false
  }
}

onMounted(()=>{
  window.addEventListener('resize', resizeScreen)
  resizeScreen()
})

onUnmounted(()=>{
  window.removeEventListener('resize', resizeScreen)
})
</script>
<style lang="scss">
.fragile-card:hover{
  .arrow-left, .arrow-right{
    opacity: 0;
  }
}
.arrow-left, .arrow-right{
  transition: ease-in .2s;
  opacity: 1;
}
.info-detail{
  p{
    line-height: 1.5;
  }
}
</style>


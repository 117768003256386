<template>
<!-- Breadcrumb -->
    <div class="fixed z-[401] md:hidden">
        <div class="filter-nav !px-5" :class="{'active' : activeMobileMenu}" aria-label="Breadcrumb">
            <div class="flex items-center justify-between mt-1">
                <div>เงื่อนไขการแสดงผลข้อมูล</div>

                <FilterIcon
                    class="w-8 h-8 transform"
                    @click="toggleMobileMenu"
                />
            </div>
            <hr class="bg-gray opacity-25 ml-[-20px] mr-[-20px] my-2" v-if="activeMobileMenu">
            <section class="grid grid-cols-12 gap-3 pb-5" v-if="activeMobileMenu">
                <div class="col-span-12 sm:col-span-6">
                    <label class="from-label text-medium text-gray-600" for="year-select">ระดับความเปราะบาง</label>
                    <Dropdown :value="getLabel" class="!block" :customButtonClass="['fragile-level-dropdown-mobile']" :customContentClass="['w-full']" id="levelMobile">
                        <template #dropdown-body>
                            <div class="p-2">
                                <div class="min-w-[140px] text-sm">
                                    <div class="form-checkbox-custom pl-6" v-for="(ch, ind) in fragile_level_options" :key="ind">
                                        <div>
                                            <label
                                                class="form-check-label align-middle flex items-baseline"
                                                :for="`m-label-${ind}`"
                                            >
                                                <input
                                                    :id="`m-label-${ind}`"
                                                    class="form-check-input align-middle"
                                                    type="checkbox"
                                                    :value="ch.key"
                                                    v-model="fragile_level"
                                                    :disabled="fragile_level.length == 1 && fragile_level.includes(ch.key)"
                                                    @change="handleCheckbox"
                                                >{{ ch.text }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="mt-3">
                                        <hr class="mt-3 mb-1 border-[#ccc]" />
                                        <p class="text-sm mb-1">แสดงผล</p>
                                        <div class="flex rounded-full border border-gray-400 overflow-hidden mx-2 w-[140px]">
                                            <fieldset class="flex-1 text-center">
                                                <input
                                                    class="peer hidden"
                                                    type="radio"
                                                    name="fragile_by"
                                                    value="hh_cnt"
                                                    id="mob_fragile_by_hh"
                                                    v-model="fragile_by"
                                                    @change="radioChange"
                                                >
                                                <label
                                                    class="inline-block text-xs w-full p-1 cursor-pointer text-theme-37 hover:bg-gray-100 peer-checked:bg-theme-4 peer-checked:text-white select-none"
                                                    for="mob_fragile_by_hh"
                                                >ครัวเรือน</label>
                                            </fieldset>
                                            <fieldset class="flex-1 text-center">
                                                <input
                                                    class="peer hidden"
                                                    type="radio"
                                                    name="fragile_by"
                                                    value="member_cnt"
                                                    id="mob_fragile_by_member"
                                                    v-model="fragile_by"
                                                    @change="radioChange"
                                                >
                                                <label
                                                    class="inline-block text-xs w-full p-1 cursor-pointer text-theme-37 hover:bg-gray-100 peer-checked:bg-theme-4 peer-checked:text-white select-none"
                                                    for="mob_fragile_by_member"
                                                >คน</label>
                                            </fieldset>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Dropdown>
                </div>
                <div class="col-span-12 sm:col-span-6">
                    <label class="from-label text-medium text-gray-600" for="year-select">ข้อมูลประจำปี</label>
                    <v-select
                        id="year-select"
                        v-model="currentYear"
                        :options="yearOption"
                        :appendToBody="true"
                        :clearable="false"
                    >
                        <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                                <CaretDownIcon class="w-4 h-4"/>
                            </span>
                        </template>
                    </v-select>
                </div>
                <div class="col-span-12 sm:col-span-6">
                    <label class="from-label text-medium text-gray-600" for="province-select">จังหวัด</label>
                    <v-select
                        id="province-select"
                        v-model="provinceSelected"
                        :options="['ทั้งประเทศ',...provinceOption]"
                        label="PROVINCE_NAME_TH"
                        :clearable="true"
                        placeholder="กรุณาเลือกจังหวัด"
                        @update:modelValue="handleUpdateProvince"
                        :appendToBody="true"
                    >
                        <template #no-options="">
                            <p class="text-sm font-normal text-gray-500">
                                ไม่พบข้อมูล
                            </p>
                        </template>
                        <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                                <CaretDownIcon class="w-4 h-4"/>
                            </span>
                        </template>
                    </v-select>
                </div>

                <div class="col-span-12 sm:col-span-6">
                    <label class="from-label text-medium text-gray-600" for="amphur-select">อำเภอ</label>
                    <v-select
                        id="amphur-select"
                        v-model="amphurSelected"
                        :options="amphurOption"
                        label="AMPHUR_NAME_TH"
                        :clearable="true"
                        placeholder="กรุณาเลือกอำเภอ"
                        @update:modelValue="handleUpdateAmphur"
                        :appendToBody="true"
                    >
                        <template #no-options="">
                            <p class="text-sm font-normal text-gray-500">
                                ไม่พบข้อมูล
                            </p>
                        </template>
                        <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                                <CaretDownIcon class="w-4 h-4"/>
                            </span>
                        </template>
                    </v-select>
                </div>

                <div class="col-span-12 sm:col-span-6">
                    <label class="from-label text-medium text-gray-600" for="tumbol-select">ตำบล</label>
                    <v-select
                        id="tumbol-select"
                        v-model="tambolSelected"
                        :options="tambolOption"
                        label="TAMBOL_NAME_TH"
                        :clearable="true"
                        placeholder="กรุณาเลือกตำบล"
                        @update:modelValue="handleUpdateTambol"
                        :appendToBody="true"
                    >
                        <template #no-options="">
                            <p class="text-sm font-normal text-gray-500">
                                ไม่พบข้อมูล
                            </p>
                        </template>
                        <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                                <CaretDownIcon class="w-4 h-4"/>
                            </span>
                        </template>
                    </v-select>
                </div>
            </section>
        </div>
    </div>
    <div class="fixed z-[401] hidden md:block">
        <nav class="flex filter-nav !px-5" :class="prepareFilterStatus ? 'justify-between' : 'justify-end'" aria-label="Breadcrumb">
            <ul class="inline-flex items-center mb-3 sm:mb-0" v-if="prepareFilterStatus">
                <li>
                    <div class="flex flex-col">
                        <span class="px-2 text-xs text-gray translate">ระดับความเปราะบาง</span>
                        <Dropdown :value="getLabel">
                            <template #dropdown-body>
                                <div class="p-2">
                                    <div class="min-w-[140px] text-sm">
                                        <div class="form-checkbox-custom pl-6" v-for="(ch, ind) in fragile_level_options" :key="ind">
                                            <div>
                                                <label
                                                    class="form-check-label align-middle flex items-baseline"
                                                    :for="`label-${ind}`"
                                                >
                                                    <input
                                                        :id="`label-${ind}`"
                                                        class="form-check-input align-middle"
                                                        type="checkbox"
                                                        :value="ch.key"
                                                        v-model="fragile_level"
                                                        :disabled="fragile_level.length == 1 && fragile_level.includes(ch.key)"
                                                        @change="handleCheckbox"
                                                    >{{ ch.text }}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="mt-3">
                                            <hr class="mt-3 mb-1 border-[#ccc]"/>
                                            <p class="text-sm mb-1">แสดงผล</p>
                                            <div class="flex rounded-full border border-gray-400 overflow-hidden mx-2">
                                                <fieldset class="flex-1 text-center">
                                                    <input
                                                        class="peer hidden"
                                                        type="radio"
                                                        name="fragile_by"
                                                        value="hh_cnt"
                                                        id="fragile_by_hh"
                                                        v-model="fragile_by"
                                                        @change="radioChange"
                                                    >
                                                    <label
                                                        class="inline-block text-xs w-full p-1 cursor-pointer text-theme-37 hover:bg-gray-100 peer-checked:bg-theme-4 peer-checked:text-white select-none"
                                                        for="fragile_by_hh"
                                                    >ครัวเรือน</label>
                                                </fieldset>
                                                <fieldset class="flex-1 text-center">
                                                    <input
                                                        class="peer hidden"
                                                        type="radio"
                                                        name="fragile_by"
                                                        value="member_cnt"
                                                        id="fragile_by_member"
                                                        v-model="fragile_by"
                                                        @change="radioChange"
                                                    >
                                                    <label
                                                        class="inline-block text-xs w-full p-1 cursor-pointer text-theme-37 hover:bg-gray-100 peer-checked:bg-theme-4 peer-checked:text-white select-none"
                                                        for="fragile_by_member"
                                                    >คน</label>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </Dropdown>
                    </div>
                </li>
                <li>
                    <div class="flex items-center pr-2">
                        <BreadcrumbIcon class="text-gray"/>
                        <div class="flex flex-col">
                            <span class="px-2 text-xs text-gray truncate">ข้อมูลประจำปี</span>
                            <Dropdown :options="yearOption" :value="currentYear" @update:dropdown="currentYear = $event"/>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="flex items-center pr-2">
                        <BreadcrumbIcon class="text-gray"/>
                        <div class="flex flex-col">
                            <span class="px-2 text-xs text-gray truncate">จังหวัด</span>
                            <Dropdown :options="provinceOption" @update:dropdown="handleUpdateProvince" :value="provinceSelected" parentValue="ทั้งประเทศ" :customContentClass="['province-filter','left-[-230px]']" label="PROVINCE_NAME_TH" multipleColumn/>
                        </div>
                    </div>
                </li>
                <li v-if="provinceSelected?.PROVINCE_NAME_TH || route.params?.level?.length >= 2">
                    <div class="flex items-center pr-2">
                        <BreadcrumbIcon class="text-gray"/>
                        <div class="flex flex-col">
                            <span class="px-2 text-xs text-gray truncate">อำเภอ</span>
                            <Dropdown :options="amphurOption" :value="amphurSelected" @update:dropdown="handleUpdateAmphur" :parentValue="provinceSelected?.PROVINCE_NAME_TH" label="AMPHUR_NAME_TH" :customContentClass="['amphur-filter', 'left-[-160px]']"/>
                        </div>
                    </div>
                </li>
                <li v-if="amphurSelected?.AMPHUR_NAME_TH || route.params?.level?.length >= 4">
                    <div class="flex items-center">
                        <BreadcrumbIcon class="text-gray"/>
                        <div class="flex flex-col">
                            <span class="px-2 text-xs text-gray truncate">ตำบล</span>
                            <Dropdown :options="tambolOption" :value="tambolSelected" @update:dropdown="handleUpdateTambol" :parentValue="amphurSelected?.AMPHUR_NAME_TH" label="TAMBOL_NAME_TH" :customContentClass="['tambol-filter', 'left-[-175px]']" />
                        </div>
                    </div>
                </li>
            </ul>
            <div class="hidden custom:block mt-[2px]">
              <button class="btn btn btn-outline-secondary rounded bg-white mr-1" @click="emits('downloadFile', 'csv')" :disabled="loadingDataStatus"><DownloadIcon class="h-5 w-5 mr-2" /> CSV</button>
              <button class="btn btn btn-outline-secondary rounded bg-white" @click="emits('downloadFile', 'xlsx')" :disabled="loadingDataStatus"><DownloadIcon class="h-5 w-5 mr-2" /> Excel</button>
            </div>
        </nav>
    </div>
</template>

<script setup>
import { onMounted, ref, watch, reactive, computed } from "vue";
import axios from "axios"
import { useRoute } from "vue-router";
import provinceData from "@/data/province.json"

const props = defineProps({
    currentLevel: String,
    currentProperties: Object,
    loadingDataStatus: {type: Boolean, default: true}
})
const emits = defineEmits(["filterChange", "downloadFile", "fragileLevel", "displayDataBy"])
const route = useRoute()
const currentYear = ref(route.params.year ? route.params.year : "2566")
const provinceSelected = ref(route.params.level ? null : "ทั้งประเทศ")
const amphurSelected = ref()
const tambolSelected = ref()
const yearOption = ref([
    "2566"
])
const provinceOption = ref([])
const amphurOption = ref([])
const tambolOption = ref([])
const filterSelected = ref({
    year: null,//currentYear.value,
    province: null,
    amphur: null,
    tambol: null,
    level: null
})
const activeMobileMenu = ref(false)
const prepareFilterStatus = ref(false)
const fragile_level = ref(["fragile_lv0", "fragile_lv1", "fragile_lv2", "fragile_lv3"])
const fragile_level_options = reactive(
  [
    {key: "fragile_lv0", text:"เปราะบางระดับ 0"},
    {key: "fragile_lv1", text:"เปราะบางระดับ 1"},
    {key: "fragile_lv2", text:"เปราะบางระดับ 2"},
    {key: "fragile_lv3", text:"เปราะบางระดับ 3"}
  ]
)
const fragile_by = ref("hh_cnt")

const toggleMobileMenu = () => {
    activeMobileMenu.value = !activeMobileMenu.value
}
const getProvince = async () => {
    provinceOption.value = provinceData
    if(route.params.level){
        provinceSelected.value = provinceOption.value.find(x=> x._id.PROVINCE_ID == route.params.level.substring(0,2))
        if(route.params.level.length == 2){
            prepareFilterStatus.value = true
            Object.assign(filterSelected.value, {level: "province", province: provinceSelected.value, year: currentYear.value})
        }
    }
}
const getAmphur = async (province_id) => {
    const res = await axios.get(`${process.env.VUE_APP_BASE_API}/map/refAdms`,{params: {PROVINCE_ID: province_id}}).catch(()=>console.log('error'))
    amphurOption.value = res.data
    if(route.params.level){
        if(route.params.level.length == 4 || route.params.level.length == 6){
            amphurSelected.value = amphurOption.value.find(x=> x._id.AMPHUR_ID == route.params.level.substring(0,4))
            if(route.params.level.length == 4){
                prepareFilterStatus.value = true
                Object.assign(filterSelected.value, {level: "amphur", province: provinceSelected.value, amphur: amphurSelected.value, year: currentYear.value})
            }
        }
    }
}
const getTambol = async (amphur_id) => {
    const res = await axios.get(`${process.env.VUE_APP_BASE_API}/map/refAdms`, {params: {AMPHUR_ID: amphur_id}}).catch(()=>console.log('error'))
    tambolOption.value = res.data
    if(route.params?.level?.length == 6){
        prepareFilterStatus.value = true
        tambolSelected.value = tambolOption.value.find(x=> x._id.TAMBOL_ID == route.params.level)
        Object.assign(filterSelected.value, {province: provinceSelected.value, amphur: amphurSelected.value, tambol: tambolSelected.value, level: "tambol", year: currentYear.value})
    }
}

watch(()=>filterSelected.value, ()=>{
    emits("filterChange", filterSelected.value)
},{deep: true})

const handleUpdateProvince = (val) =>{
    if(val?._id?.PROVINCE_ID){
            window.location.href = `/${currentYear.value}/${val._id.PROVINCE_ID}`
    }
    else{
        if(route.params.level.length >= 2)
            window.location.href = `/${currentYear.value}`
    }
}
const handleUpdateAmphur = (val) =>{
    if(val?._id?.AMPHUR_ID){
            window.location.href = `/${currentYear.value}/${val._id.AMPHUR_ID}`
    }
    else{
        if(route.params.level.length != 2)
            window.location.href = `/${currentYear.value}/${provinceSelected.value._id.PROVINCE_ID}`
    }
}
const handleUpdateTambol = (val) =>{
    if(val?._id?.TAMBOL_ID){
        window.location.href = `/${currentYear.value}/${val._id.TAMBOL_ID}`
    }
    else{
        if(route.params.level.length != 4)
            window.location.href = `/${currentYear.value}/${amphurSelected.value._id.AMPHUR_ID}`
    }
}

watch(provinceSelected, (val) => {
    if(val?._id?.PROVINCE_ID){
        if(route.params.level.length < 4)
            amphurSelected.value = "ทุกอำเภอ"
        getAmphur(val?._id?.PROVINCE_ID)
    }
    else{
        amphurOption.value = []
        tambolOption.value = []
    }
});
watch(amphurSelected, (val)=>{
    if(val?._id?.AMPHUR_ID){
        if(route.params.level.length < 6)
            tambolSelected.value = "ทุกตำบล"
        getTambol(val?._id?.AMPHUR_ID)
    }
    else{
        tambolOption.value = []
    }
})
watch(currentYear, (val)=>{
    window.location.href=`/${val}/${route.params.level}`
})


watch(()=> props.currentProperties, (val)=>{
    if(val.level == "province"){
        handleUpdateProvince(val.province)
    }
    else if(val.level == "amphur"){
        handleUpdateAmphur(val.amphur)
    }
    else if(val.level == "tambol"){
        handleUpdateTambol(val.tambol)
    }
})

onMounted(async ()=>{
    if(!route.params.level){
        prepareFilterStatus.value = true
        Object.assign(filterSelected.value, {
            year: currentYear.value,
            province: "ทั้งประเทศ",
            amphur: null,
            tambol: null,
            level: "all"
        })
    }
    await getProvince()
})

const handleCheckbox = (evt) =>{
    emits("fragileLevel", fragile_level.value)
}

const getLabel = computed(()=>{
    let temp = `${fragile_by.value == 'hh_cnt' ? 'ครัวเรือน' : 'คน'}เปราะบางระดับ `
    if(fragile_level.value.length == 4){
        return `${fragile_by.value == 'hh_cnt' ? 'ครัวเรือน' : 'คน'}เปราะบางทั้งหมด`
    }
    for(let i = 0 ;i < fragile_level.value.sort((a, b)=>a.localeCompare(b, undefined, { numeric: true })).length; i++){
        if(fragile_level.value[i] == "fragile_lv0"){
            temp += "0"
        }
        if(fragile_level.value[i] == "fragile_lv1"){
            temp += "1"
        }
        if(fragile_level.value[i] == "fragile_lv2"){
            temp += "2"
        }
        if(fragile_level.value[i] == "fragile_lv3"){
            temp += "3"
        }
        if(i < fragile_level.value.length - 1){
            temp += ", "
        }
    }
    return temp
})

const radioChange = (evt) => {
    emits('displayDataBy', evt.target._value)
}
</script>

<style lang="scss">
.filter-nav{
    transition: all .3s ease;
    &.active{
        height: auto;
    }
}
section{
    transition: all .3s ease;
}
.fragile-level-dropdown-mobile{
    font-size: 0.875rem;
    color: #333333;
    border: 1px solid #cccc;
    padding: 6px 8px !important;
    border-radius: 4px !important;
}
</style>